import React from 'react';
import './Banner.scss';
import { Fade } from 'react-awesome-reveal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAndroid, faApple } from '@fortawesome/free-brands-svg-icons';
import { faChevronDown } from '@fortawesome/free-solid-svg-icons';

const Banner = () => {
  return (
    <div className="section-banner bg-market">
      <img src="assets/img/theme/banner-theme-1.png" alt="banner-theme-2" className="banner-theme-1" />
      <img src="assets/img/theme/banner-theme-2.png" alt="banner-theme-2" className="banner-theme-2" />
      <img src="assets/img/theme/banner-theme-2.png" alt="banner-theme-3" className="banner-theme-3" />
      <div className="container home-page-container">
        <div className="row home-page-row">
          <Fade triggerOnce duration={1000} direction='up' className="col-lg-6 col-sm-12 home-page-fade">
            <div className="hero-content">
              <div className="banner-main">
                <div className="zx-app-banner">
                  {/* <img src="assets/img/theme/theme_1.png" alt="theme_1" className="theme_1_appearing" /> */}
                  <div className="typing-div">
                    <div className="typewriter">
                      <h1>We build mobile apps&nbsp;</h1>
                    </div>
                  </div>
                </div>
                <div className="zx-app-paragraph">
                    <p>            
                      Build once and deploy to all
                    </p>
                </div>
                <div className="zx-app-reviews">
                  <a href="#features" className="btn zx-app-buttons"><FontAwesomeIcon icon={faChevronDown} />&nbsp;Explore</a>
                  {/* <div className="reviews-inner">
                    <h4>15K Reviews</h4>
                  </div> */}
                  <div className='d-flex align-items-center'>
                    <FontAwesomeIcon icon={faApple} fontSize={40} style={{color:'silver'}} />
                    &nbsp;&nbsp;&nbsp;&nbsp;
                    <FontAwesomeIcon icon={faAndroid} fontSize={40} style={{color:'silver'}} />
                  </div>
                  {/* <div className="zx-app-icons">
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                    <i className="fa-solid fa-star"></i>
                  </div> */}
                  <img src="assets/img/theme/theme_2.png" alt="theme_2" className="theme_2" />
                </div>
                {/* <div className="zx-app-rounded">
                  <img src="assets/img/testimonials/1.jpg" alt="reviews" />
                  <img src="assets/img/testimonials/2.jpg" alt="reviews" />
                  <img src="assets/img/testimonials/3.jpg" alt="reviews" />
                  <img src="assets/img/testimonials/4.jpg" alt="reviews" />
                </div> */}
              </div>
            </div>
          </Fade>
          <Fade triggerOnce duration={1500} direction='up' className="col-lg-6 col-sm-12">
            <div className="hero-content-img">
              <img src="assets/img/theme/plus.png" alt="plus" className="plus-effcat" />
              <img src="assets/img/theme/theme_3.png" alt="theme_3" className="theme_3 d-none d-lg-block" />
              <img src="assets/img/services/featured1/icons-on-phone.png" alt="" className="hero_image" />
            </div>
          </Fade>
        </div>
      </div>
    </div>
  )
}

export default Banner;